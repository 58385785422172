<template>
  <v-container>

    <v-row justify="space-between">
      <v-col cols="6">
        <v-btn-toggle v-model="usersStatus" color="primary">
          <v-btn text small value="all" style="height: 50px;">All</v-btn>

          <v-btn text small value="active" style="height: 50px;">
            Active
          </v-btn>
          <v-btn text small value="Assigned" style="height: 50px;">
            Confirmed
          </v-btn>
          <v-btn text small value="InProgress" style="height: 50px;">
            InProgress
          </v-btn>
          <v-btn text small value="Expired" style="height: 50px;">
            Expired
          </v-btn>
          <v-btn text small value="Completed" style="height: 50px;">
            Completed
          </v-btn>
          <v-btn text small value="Cancelled" style="height: 50px;">
            Cancelled
          </v-btn>
        </v-btn-toggle>
      </v-col>
      <v-col cols="6" style="margin-top: 8px">
        <v-row>
          <div style="width: 220px">
            <date-filter :init-date="initDate" @dateChange="onDateChange" />
          </div>

          <v-text-field label="Search Bookings" solo append-icon="mdi-magnify" @change="search"  style="margin-right:12px"/>
          <!-- <a href="#" @click.prevent="
            exportToCSV()
            ">
            <v-btn color="primary" style="margin:5px; ">
              <v-icon small> mdi-download </v-icon>
              Export
            </v-btn>
          </a> -->
        </v-row>
      </v-col>
      
    </v-row>
    <v-row style="height: 450px; margin-bottom: 15px; min-height: 434px;">
      <v-container class="fill-height" fluid>
        <v-row style="height: 100%;">
          <v-col cols="6">
            <v-card style="height: 100%;">
              <base-material-card color="primary" style="height: 100%;">
                <template v-slot:heading>
                  <div class="text-h3 font-weight-light">
                    Pickup Adresses HeatMap
                  </div>
                </template>
                <template align="right">
                  <vue-google-heatmap :points="pickupPoints" width="100%" height="93%" :lat="pickupPoints[0].lat"
                    :lng="pickupPoints[0].lng" :initial-zoom="5" :options="options" />
                </template>
              </base-material-card>
            </v-card>
          </v-col>
          <v-col cols="6">
            <v-card style="height: 100%;">
              <base-material-card color="primary" style="height: 100%;">
                <template v-slot:heading>
                  <div class="text-h3 font-weight-light">
                    Dropoff Adresses HeatMap
                  </div>
                </template>
                <template align="right">
                  <vue-google-heatmap :points="dropoffPoints" width="100%" height="93%" :lat="dropoffPoints[0].lat"
                    :lng="dropoffPoints[0].lng" :initial-zoom="5" :options="options" />
                </template>
              </base-material-card>
            </v-card>
          </v-col>
        </v-row>
      </v-container>
    </v-row>
    <v-card class="mt-15">
      <base-material-card color="primary">
        <template v-slot:heading>
          <div class="text-h3 font-weight-light">
            Booking Details
          </div>
        </template>
        <v-data-table :headers="headers" :items="bookings.data" :key="requestTableKey" :options.sync="tableOptions" :loading="loading"
          :server-items-length="tableOptions.totalItems"
          map-type="roadmap" class="elevation-1" :footer-props="{
            'items-per-page-options': tableOptions.rowsPerPageItems,
          }">
          <template v-slot:[`item.createdAt`]="{ item }">
            {{ getReadableDate(item.createdAt) }}
          </template>
          <template v-slot:[`item._id`]="{ item }">
            {{ constructId(item._id) }}
          </template>
          <template v-slot:[`item.requestType`]="{ item }">
            {{ getReadableRequestType(item.requestType) }}
          </template>

          <template v-slot:[`item.status`]="{ item }">
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-chip v-if="item.status == 'active'" class="ma-2" color="orange" text-color="white" v-bind="attrs"
                  v-on="on">
                  Active
                </v-chip>
              </template>
              <span>
                Qoute has been created, yet to accept by customer.
              </span>
            </v-tooltip>

            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-chip v-if="item.status == 'Arrived'" class="ma-2" color="#EA8FEA" text-color="white" v-bind="attrs"
                  v-on="on">
                  Arrived
                </v-chip>
              </template>
              <span>
                Driver arrived at the pickup location.
              </span>
            </v-tooltip>

            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-chip v-if="item.status == 'driver_started'" class="ma-2" color="#A0D8B3" text-color="white" v-bind="attrs"
                  v-on="on">
                  Driver Started
                </v-chip>
              </template>
              <span>
                Driver started the ride.
              </span>
            </v-tooltip>

            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-chip v-if="item.status == 'Completed'" class="ma-2" color="green" text-color="white" v-bind="attrs"
                  v-on="on">
                  Completed
                </v-chip>
              </template>
              <span>
                Ride completed successfully.
              </span>
            </v-tooltip>

            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-chip v-if="item.status == 'Cancelled'" class="ma-2" color="red" text-color="white" v-bind="attrs"
                  v-on="on">
                  Cancelled
                </v-chip>
              </template>
              <span>
                Booking canceled either by driver or customer.
              </span>
            </v-tooltip>

            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-chip v-if="item.status == 'Assigned'" class="ma-2" color="light-blue lighten-1" text-color="white"
                  v-bind="attrs" v-on="on">
                  Assigned
                </v-chip>
              </template>
              <span>
                Ride has been assigned to a driver.
              </span>
            </v-tooltip>

            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-chip v-if="item.status == 'Expired'" class="ma-2" color="deep-orange darken-4" text-color="white"
                  v-bind="attrs" v-on="on">
                  Expired
                </v-chip>
              </template>
              <span>
                Time out, no driver has accepted ride during the breakdown time.
              </span>
            </v-tooltip>

            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-chip v-if="item.status == 'InProgress'" class="ma-2" color="#8CBA51" text-color="white"
                  v-bind="attrs" v-on="on">
                  In Progress
                </v-chip>
              </template>
              <span>
                Ride in progess.
              </span>
            </v-tooltip>

            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-chip v-if="item.status == 'keysPickedUp'" class="ma-2" color="cyan" text-color="white" v-bind="attrs"
                  v-on="on">
                  Keys Picked Up
                </v-chip>
              </template>
              <span>
                Rider has picked up the keys and on the way to customer
                location.
              </span>
            </v-tooltip>
          </template>

          <template v-slot:[`item.actions`]="{ item }">
            <v-tooltip :key="index" bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-icon class="mr-2" v-bind="attrs" v-on="on" @click="goToRequest(item._id)">
                  mdi-eye
                </v-icon>
              </template>
              View
            </v-tooltip>

            <v-tooltip :key="index" bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  class="mr-2"
                  v-bind="attrs"
                  v-on="on"
                  @click="deleteRequestAction(item)"
                >
                  mdi-delete
                </v-icon>
              </template>
              Delete
            </v-tooltip>
          
          </template>
          <template v-slot:[`item.pickup.address`]="{ item }">
            <v-tooltip top>
              <template v-slot:activator="{ on }">
                <div v-on="on">
                  {{ getShortAddress(item.completePickupAddress) }}
                </div>
              </template>
              <span>{{ item.completePickupAddress }}</span>
            </v-tooltip>
          </template>
          <template v-slot:[`item.dropoff.address`]="{ item }">
            <v-tooltip top>
              <template v-slot:activator="{ on }">
                <div v-on="on">
                  {{ getShortAddress(item.completeDropoffAddress) }}
                </div>
              </template>
              <span>{{ item.completeDropoffAddress }}</span>
            </v-tooltip>
          </template>
        </v-data-table>
      </base-material-card>
    </v-card>
  </v-container>
</template>
<script>
import { getBookings, bookingStats, getRequestDataInCSV, deleteRequest } from '@/services/admin'
import bookingModal from '@/components/bookingModal'
import DateFilter from '@/components/dateFilter'
import Vue from 'vue'

//googleheatmaps api
import VueGoogleHeatmap from 'vue-google-heatmap'
import couponListVue from '../../components/couponList.vue'
Vue.use(VueGoogleHeatmap, {
  apiKey: process.env.VUE_APP_API_KEY_GOOGLE_MAP,
})

export default {
  components: { bookingModal, DateFilter, VueGoogleHeatmap },
  data() {
    return {
      height: '100%',
      width: '100%',
      pickDropAdrs: null,
      pickupPoints: [],
      dropoffPoints: [],
      countRecord: 0,

      options: {
        opacity: 1,
      },

      userFormOpen: false,
      usersStatus: 'all',
      timeStatus: 'all',
      load: false,
      showLoad: false,
      stats: null,
      requestTableKey: 0,
      headers: [
        {
          sortable: false,
          text: 'ID',
          value: '_id',
        },
        {
          sortable: false,
          text: 'Customer',
          value: 'customer',
        },
        {
          sortable: false,
          text: 'Pickup Location',
          value: 'pickup.address',
        },
        {
          sortable: false,
          text: 'Dropoff Location',
          value: 'dropoff.address',
        },
        {
          sortable: false,
          text: 'Request Type',
          value: 'requestType',
          width: '120px',
        },
        // {
        //   sortable: false,
        //   text: 'Price',
        //   value: 'price'
        // },
        {
          sortable: false,
          text: 'Created on',
          value: 'createdAt',
        },
        {
          sortable: false,
          text: 'Driver Activity',
          value: 'rideStatus',
          width: '110px',
        },
        {
          sortable: false,
          text: 'status',
          value: 'status',
          width: '110px',
        },
        {
          text: 'Action',
          align: 'start',
          sortable: false,
          value: 'actions',
        },
      ],
      bookings: {
        data: [],
        actions: [],
      },
      tableOptions: {
        page: 1,
        itemsPerPage: 200,
        totalItems: 0,
        rowsPerPageItems: [200, 500, 1000, 2000],
      },
      loading: false,
      searchKeyword: '',
      bookingModalShow: false,
      bookingData: null,
      dateFilter: null,
      initDate: [],
    }
  },
  watch: {
    tableOptions: {
      handler() {
        // this.loading = true
        const _self = this
        // setTimeout(function () {
        //   _self.getData()
        // }, 500)
      },
    },
    usersStatus() {
      this.getData()
    },
  },
  async mounted() {
    // const date = new Date();
    // if (!localStorage.getItem("filterDate")) {
    //   this.initDate.push(`${date.getFullYear()}-${date.getMonth() + 1}-01`);
    //   this.initDate.push(
    //     `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`
    //   );
    // } else {
    //   let datearr = localStorage.getItem("filterDate").split(",");
    //   let date1 = new Date(datearr[0]);
    //   let date2 = new Date(datearr[1]);
    //   this.initDate.push(
    //     `${date1.getFullYear()}-${date1.getMonth() + 1}-${date1.getDate()}`
    //   );
    //   this.initDate.push(
    //     `${date2.getFullYear()}-${date2.getMonth() + 1}-${date2.getDate()}`
    //   );
    // }
    this.getData()
  },
  methods: {
    search(keyword) {
      this.searchKeyword = keyword
      this.getData()
    },
    onDateChange(newValue) {
      if (newValue.length < 2) return;
      localStorage.setItem("filterDate", newValue);
      let finaldate = localStorage.getItem("filterDate").split(",");
      this.dateFilter = localStorage.getItem("filterDate")
        ? [new Date(finaldate[0]), new Date(finaldate[1])]
        : newValue;
      this.getData()
    },
    async getData() {
      this.loading = true
      const params = {
        status: this.usersStatus === 'all' ? null : this.usersStatus,
        search: this.searchKeyword,
        limit: this.tableOptions.itemsPerPage || 30,
        page: this.tableOptions.page,
        date: this.searchKeyword ? null : this.dateFilter?.join(','),
      }
      getBookings(params).then((res) => {
        this.pickupPoints = []
        this.dropoffPoints = []
        this.countRecord = 0
        let resultBooking = []

        //if no record found
        if (res.data.msg || res.data?.request?.length == 0) {
          this.pickupPoints = [
            {
              lat: null,
              lng: null,
            },
          ]
          this.dropoffPoints = [
            {
              lat: null,
              lng: null,
            },
          ]
          this.bookings.data = []
          this.loading = false
          return
        }

        res.data?.request?.forEach((element, i) => {
          let row = {
            _id: element._id,
            customer: `${element.customer?.firstName}  ${element.customer?.surName}`,
            'pickup.address': element.pickup.address,
            //adding just for rendering (pickup.address) this syntax while rendering booking table creaing problem
            completePickupAddress: element.pickup.address,
            'dropoff.address': element.dropoff.address,
            completeDropoffAddress: element.dropoff.address,
            requestType: element.requestType,
            status: element.status,
            createdAt: element.createdAt,
          }
          resultBooking.push(row)
          this.pickupPoints = this.pickupPoints?.concat([
            {
              lat: element.pickup?.location?.coordinates[1],
              lng: element.pickup?.location?.coordinates[0],
            },
          ])
          this.dropoffPoints = this.dropoffPoints.concat([
            {
              lat: element.dropoff.location.coordinates[1],
              lng: element.dropoff.location.coordinates[0],
            },
          ])
          this.countRecord = this.countRecord + 1
          if (res.data?.request?.length - 1 == i) {
            console.log("🚀 ~ file: CallRequests.vue:437 ~ res.data?.request?.forEach ~ i:", i)
            console.log("🚀 ~ file: CallRequests.vue:437 ~ res.data?.request?.forEach ~ res.data?.request?.length:", res.data?.request?.length)
            this.requestTableKey += 1;
          }
          // console.log(
          //   'pickup and dropoff points',
          //   this.pickupPoints,
          //   this.dropoffPoints,
          //   '\ntotal:',
          //   this.countRecord,
          // )
        })
        this.tableOptions.totalItems = res.data?.count;
        this.bookings.data = resultBooking
        this.bookings.data.map(booking => {
          booking.rideStatus = booking.requestType == 'breakdown' ? this.getCorrectStatusTextBreakdown(booking) : this.getCorrectStatusTextRelocate(booking)
        })

        this.loading = false
      }).catch(e => {
        console.log("🚀 ~ file: CallRequests.vue:493 ~ getBookings ~ e:", e)
        this.loading = false

      })
    },

    getReadableDate(date) {
      const d = new Date(date)
      return d.toUTCString()
    },
    getReadableRequestType(type) {
      if (type === 'RelocateNow') {
        return 'Relocate Now'
      } else if (type === 'breakdown') {
        return 'Break Down'
      } else if (type === 'RelocateScheduled') {
        return 'Relocate Scheduled'
      } else return type
    },
    getReadableRequestStatus(status) {
      if (status === 'active') {
        return 'Active'
      } else return status
    },
    goToRequest(id) {
      this.$router.push({ name: 'Request Detail', params: { id: id } })
    },
    constructId(id) {
      return (id?.slice(17, 19) + "-" + id?.slice(19)).toUpperCase()
    },
    getShortAddress(address) {
      return address.length > 25 ? `${address?.slice(0, 25)}...` : address
    },
    async exportToCSV() {
      const params = {
        status: this.usersStatus === "all" ? null : this.usersStatus,
        search: this.searchKeyword,
        limit: this.searchKeyword ? 30 : this.tableOptions.itemsPerPage,
        page: this.tableOptions.page,
        date: this.searchKeyword ? null : this.dateFilter?.join(","),
      };
      console.log('called csv');
      await getRequestDataInCSV(params).then((res) => {
        this.json_data = res.data;
        const blob = new Blob([this.json_data], { type: "text/csv" });
        const link = document.createElement("a");
        link.href = URL.createObjectURL(blob);
        link.download = 'booking_details.csv';
        link.click();
        URL.revokeObjectURL(link.href);
      });

    },
    getCorrectStatusTextBreakdown(requestDetails) {
      console.log('called 1 ', requestDetails.status);
      if (requestDetails.status == 'Assigned' || requestDetails.status == 'active') {
        return 'Winch not started'
      } else if (requestDetails.status == 'driver_started') {
        return 'En route to vehicle pickup'
      } else if (requestDetails.status == 'Arrived') {
        return 'Arrived at vehicle pickup'
      } else if (requestDetails.status == 'InProgress') {
        return 'En route to vehicle dropoff'
      } else if (requestDetails.status == 'Completed') {
        return 'Ride is Completed'
      } else {
        return 'Ride is ' + (requestDetails.status).toUpperCase()
      }
    },
    getCorrectStatusTextRelocate(requestDetails) {
      console.log('called 2 ', requestDetails.status);
      if (requestDetails.status == 'Assigned' || requestDetails.status == 'active') {
        return 'Winch not started'
      } else if (requestDetails.status == 'driver_started' && requestDetails?.isKeyAtPickupLocation == 'Yes') {
        return 'En route to vehicle pickup'
      } else if (requestDetails.status == 'driver_started' && requestDetails?.isKeyAtPickupLocation !== 'Yes') {
        return 'En route to key pickup'
      } else if (requestDetails.status == 'Arrived' && requestDetails?.isKeyAtPickupLocation == 'Yes') {
        return 'Arrived at vehicle pickup'
      } else if (requestDetails.status == 'Arrived' && requestDetails?.isKeyAtPickupLocation !== 'Yes') {
        return 'Arrived at key pickup'
      } else if (requestDetails.status == 'keysPickedUp') {
        return 'En route to vehicle pickup'
      } else if (requestDetails.status == 'InProgress') {
        return 'En route to vehicle dropoff'
      } else if (requestDetails.status == 'Completed') {
        return 'Ride is Completed'
      } else {
        return 'Ride is ' + (requestDetails.status).toUpperCase()
      }
    },
    deleteRequestAction(item) {
      if (confirm("Are your Sure?")) {
        deleteRequest(item._id).then((res) => {
          if (res.data) {
            this.getData();
            return alert("Request Delete Successfully!");
          }
          else return alert("Try Again! Some went wrong.");
        });
      }
    },
  },

  // components: {
  //   VueGoogleHeatmap,
  // },
}
</script>
